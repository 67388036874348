import './App.scss';

function App() {
    return (
        <div className="App">
            <h1>
                home.oddy.no
            </h1>
            <ul>
                <li>
                    <a href="https://plex.oddy.no">plex.oddy.no</a>
                </li>
                <li>
                    <a href="https://sonarr.oddy.no">sonarr.oddy.no</a>
                </li>
                <li>
                    <a href="https://radarr.oddy.no">radarr.oddy.no</a>
                </li>
                <li>
                    <a href="https://rtorrent.oddy.no">rtorrent.oddy.no</a>
                </li>
                <li>
                    <a href="https://jackett.oddy.no">jackett.oddy.no</a>
                </li>
                <li>
                    <a href="https://plexmon.oddy.no">plexmon.oddy.no</a>
                </li>
            </ul>
        </div>
    );
}

export default App;
